import { useState } from "react";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import * as FAQStyles from "../temp_style/organisms/_faq.module.scss";

function FAQSection({ content }) {
  const { isMobile } = useDeviceDetect();
  const [selected, setSelected] = useState([]);

  const tabs = [];

  const handleClick = (e, index) => {
    if (selected.indexOf(index) > -1) {
      setSelected([...selected.filter((item) => item !== index)]);
    } else {
      setSelected([...selected, index]);
    }
  };

  for (const question in content) {
    const currentIndex = Object.keys(content).indexOf(question);
    function createMarkup() {
      return { __html: content[question] };
    }
    tabs.push(
      <div className={FAQStyles.faq__tabs__tab} key={currentIndex}>
        <label
          htmlFor="chck1"
          className={`${FAQStyles.faq__tabs__tabLabel} ${
            selected.indexOf(currentIndex) > -1 ? FAQStyles.tabLabelChecked : ""
          }`}
          index={currentIndex}
          onClick={(e) => handleClick(e, currentIndex)}
        >
          {question}
          <span />
        </label>
        <div
          className={`${FAQStyles.faq__tabs__tabContent} ${
            selected.indexOf(currentIndex) > -1 ? FAQStyles.tabContentChecked : ""
          }`}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </div>
    );
  }

  return (
    <>
      <h2 className={`${FAQStyles.faq__title}`}>
        {isMobile ? "FAQ" : "FREQUENTLY ASKED QUESTIONS"}
      </h2>
      <div className={FAQStyles.faq__tabs}>{tabs}</div>
    </>
  );
}

export default FAQSection;
