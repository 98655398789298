import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SEO from "../../components/seo";

// temporary styles
import * as Styles from "./influencer.module.scss";
import * as FAQStyles from "../temp_style/organisms/_faq.module.scss";
import * as HelperStyles from "../temp_style/tools/_helpers.module.scss";

// temporary images
import BackgroundAmbassador from "../temp_images/background-ambassador.jpg";
import IconStar from "../temp_images/icon-star.svg";

import Inf1 from "../temp_images/influencer_4.jpg";
import Inf2 from "../temp_images/influencer_5.jpg";
import Inf3 from "../temp_images/influencer_6.jpg";
import Inf4 from "../temp_images/influencer_7.jpg";
import Inf5 from "../temp_images/influencer_8.jpg";
import Inf6 from "../temp_images/influencer_9.jpg";
import Inf7 from "../temp_images/influencer_10.jpg";

import IconCoins from "../temp_images/icon-coins.svg";
import IconHands from "../temp_images/icon-hands.svg";
import IconGift from "../temp_images/icon-gift.svg";

import FAQSection from "../faq_section/FAQSection";
import ALButton from "../../components/al_components/ALButton";

function InfluencerPage({ path }) {
  const FAQContent = {
    "How does the collaboration work?":
      "You will receive products of your choice in order for you to create an honest review of our brand. Together we will decide what the partnership will look like, what your content will be, and what we would like to communicate depending on active campaigns.",
    "Which social media platforms are you interested in?":
      "We are primarily interested in YouTube, TikTok, and Instagram.",
    "I am a creator with a smaller following. Would you still be open to collaborating with me?":
      "Absolutely! We value your content above all else, regardless of the size of your following.",
    "Do you work with creators internationally?":
      "Yes! We work with content creators based in the United States and internationally.",
    "When will I hear back about my Influencer application?":
      "It can take a few days – please be patient! Our team receives a high volume of requests and we do our best to get in touch as soon as possible. If you do not receive a reply, it means that the campaigns we are running at the moment are not a fit.",
    "How many pieces of jewelry will I receive?":
      "We will decide together how many jewelry pieces to send you. The styles we send will depend on the type of collaboration and available inventory. Our team will usually ask for a list of your preferred products, and will do our best to accommodate your request. After the collaboration is complete, you are welcome to keep the products.",
    "Where is your jewelry made and what materials do you use?": `We use only the highest quality materials including Sterling Silver, freshwater pearls, gold plated brass, and lab grown diamonds. All our manufacturing processes are described <a href="/pages/our-people">here</a>.`,
    "Do I have to disclose this partnership to my audience?":
      "Yes! Each content creator is obligated to provide a clear and conspicuous disclosure of this partnership in any and all posts published as a result of their collaboration with our brand.",
  };

  const [popupIsActive, setPopupIsActive] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `(function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })()`;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  const openPopup = () => {
    const header = document.getElementById("header");
    if (header) {
      header.style.position = "initial";
    }
    setPopupIsActive(true);
  };
  const closePopup = () => {
    const header = document.getElementById("header");
    if (header) {
      header.style.position = null;
    }
    setPopupIsActive(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <div
        className={`${Styles.influencer__popup} ${
          popupIsActive ? Styles.influencer__popup_active : null
        }`}
      >
        <div className={Styles.influencer__close_popup} aria-label="close" role="button">
          <svg
            tabIndex="0"
            onClick={closePopup}
            aria-hidden="true"
            focusable="false"
            className="icon icon-close"
            viewBox="0 0 64 64"
          >
            <path className={`${Styles.cls1} cls-1`} d="M19 17.61l27.12 27.13m0-27.12L19 44.74" />
          </svg>
        </div>
        <div
          className="typeform-widget"
          data-url="https://analuisa14.typeform.com/to/BnwWHk"
          style={{ width: `${92}%`, height: `${500}px`, transform: "translate(4%, 90px)" }}
        >
          <div className="widget__WidgetWrapper-sc-1rxjz1n-0 NwqzB">
            <div className="widget__IframeWrapper-sc-1rxjz1n-2 cKLpKc">
              <iframe
                frameBorder="0"
                height="100%"
                src="https://analuisa14.typeform.com/to/BnwWHk?typeform-embed=embed-widget&amp;typeform-embed-id=zear5"
                width="100%"
                allow="camera; microphone; autoplay; encrypted-media;"
                data-qa="iframe"
                title="typeform-embed"
                style={{ border: `${0}px` }}
              />
            </div>
          </div>
        </div>
      </div>
      <SEO
        title="Become an influencer"
        description="Discover the perfect accessory to elevate your style with Analuisa's Handle Collection. Shop now and become an influencer with our stunning and versatile handles."
      />
      <section className={Styles.influencer__intro}>
        <div className={Styles.influencer__intro__description}>
          <div
            className={`${HelperStyles.container} ${Styles.influencer__intro__description__container}`}
          >
            <h1 className="al_h1">Ana Luisa Influencers</h1>
            <h4 className="al_h4">
              At Ana Luisa we care about our family of creators. We value quality content, great
              communication as well as environmental consciousness.
            </h4>
            <ALButton
              onClick={openPopup}
              variant="secondary"
              className={Styles.influencer__intro__button}
            >
              APPLY NOW
            </ALButton>
          </div>
        </div>

        <div className={Styles.influencer__intro__description__image}>
          <img src={BackgroundAmbassador} alt="Ana Luisa Jewelry Brand Ambassador" />
        </div>
      </section>

      <section className="influencer__benefits">
        <div className={Styles.influencer__benefits__influencers}>
          <h2 className="al_h2">They love Ana Luisa</h2>
          <ul className={Styles.influencer__benefits__influencers__profiles}>
            <li>
              <img src={Inf1} alt="Ana Luisa Jewelry Woman holding mirror" />
            </li>
            <li>
              <img src={Inf2} alt="Ana Luisa Jewelry Woman" />
            </li>
            <li>
              <img src={Inf3} alt="Ana Luisa Jewelry Woman holding baby" />
            </li>
            <li>
              <img src={Inf4} alt="Ana Luisa Jewelry Smiling selfie" />
            </li>
            <li>
              <img src={Inf5} alt="Ana Luisa Jewelry Mirror selfie with rings and necklaces" />
            </li>
            <li>
              <img src={Inf6} alt="Ana Luisa Jewelry Mirror selfie with rings and earrings" />
            </li>
            <li>
              <img src={Inf7} alt="Ana Luisa Jewelry Selfie with earrings" />
            </li>
          </ul>
        </div>
        <div className={Styles.influencer__benefits__details}>
          <div className={HelperStyles.container}>
            <h2 className="al_h3">Benefits of working with us:</h2>
            <div className={Styles.influencer__benefits__details__list}>
              <dl>
                <dt className={Styles.influencer__benefits__details__list__image}>
                  <img src={IconStar} alt="Ana Luisa Jewelry Review" />
                </dt>
                <dd
                  className={`${Styles.influencer__benefits__details__list__text} al_p al_p--bold`}
                >
                  No scripts, all genuine reviews
                </dd>
              </dl>
              <dl>
                <dt className={Styles.influencer__benefits__details__list__image}>
                  <img src={IconCoins} alt="Ana Luisa Jewelry Fair Work" />
                </dt>
                <dd
                  className={`${Styles.influencer__benefits__details__list__text} al_p al_p--bold`}
                >
                  Fair compensation for your work
                </dd>
              </dl>
              <dl>
                <dt className={Styles.influencer__benefits__details__list__image}>
                  <img src={IconHands} alt="Ana Luisa Jewelry Long Term Relationship" />
                </dt>
                <dd
                  className={`${Styles.influencer__benefits__details__list__text} al_p al_p--bold`}
                >
                  Long term relationship
                </dd>
              </dl>
              <dl>
                <dt className={Styles.influencer__benefits__details__list__image}>
                  <img src={IconGift} alt="Ana Luisa Jewelry Giveaways" />
                </dt>
                <dd
                  className={`${Styles.influencer__benefits__details__list__text} al_p al_p--bold`}
                >
                  VIP giveaways
                </dd>
              </dl>
            </div>
            <ALButton className={Styles.influencer__benefits__details__button} onClick={openPopup}>
              APPLY NOW
            </ALButton>
          </div>
        </div>
      </section>

      <section className={Styles.influencer__ambassador}>
        <div className={Styles.influencer__ambassador__profile}>
          <div className={HelperStyles.container}>
            <p className="al_p al_p--bold al_p--cap">Ambassador</p>
            <p className="al_h1">Cari Clark</p>
            <p className="al_h4">
              "Working with Ana Luisa is honestly such a treat. Every single person I've been in
              contact with has been so kind and trusts me as a creator to share their products using
              my own style and vision. I love promoting them because not only do I love what they
              make, but I also love the people behind it all :)"
            </p>
          </div>
        </div>

        <div className={Styles.influencer__ambassador__image}>
          <img src={BackgroundAmbassador} alt="Ana Luisa Jewelry Brand Ambassador" />
        </div>
      </section>

      <section className={Styles.influencer__faq}>
        <div className={`${HelperStyles.container} ${FAQStyles.faq__container}`}>
          <FAQSection content={FAQContent} />
        </div>
      </section>
    </>
  );
}

export default InfluencerPage;
